import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Fade } from 'react-reveal';

// import Fade from '@material-ui/core/Fade';
const MiniCarbon = (props) => (
	<section
		id='minicarbon'
		className='spotlight style2 right inactive'
		style={{
			backgroundImage: `url(https://images.unsplash.com/photo-1571091374658-6d880f0eab29?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMTIyMnwwfDF8c2VhcmNofDF8fGZlZWR8ZW58MHx8fHwxNjIzNjYzNDg4&ixlib=rb-1.2.1&q=80&w=1080)`,
		}}
	>
		<Fade right big>
			<div className='content'>
				<header>
					<h2>Carbon Crediting</h2>
					<p>Mitigating Climate Change</p>
				</header>
				<p>
					<b>
						We generate our own carbon credits through our{' '}
						<a href='/tech/fibre'>Allied Fibres Program</a> - which we will put
						up for sale as well, for companies and governments keen on meeting
						their emissions targets.
					</b>
				</p>
				<ul className='actions'>
					<li>
						<a href='/tech/carboncrediting' className='button'>
							Learn More
						</a>
					</li>
				</ul>
			</div>
		</Fade>
		<ScrollLink
			to='miniverti'
			className='goto-next'
			activeClass='active'
			smooth={true}
			offset={0}
			duration={1500}
			spy={true}
		>
			Next
		</ScrollLink>
	</section>
);

export default MiniCarbon;
