import React from 'react';
import logov1 from '../assets/images/logov1.png';
import { Link as ScrollLink } from 'react-scroll';
import ReactTypingEffect from 'react-typing-effect';

const Banner = (props) => (
	<section id='banner'>
		<div className='content'>
			<header>
				<h2 style={{ color: `white` }}>
					Building Singapore's Food and Climate Resilience
				</h2>
				<p style={{ color: `white` }}>
					Together, we can do more!
					<br />
					<b>
						<p style={{ color: `#e44c66`, fontSize: `1em` }}>
							<ReactTypingEffect
								text={[
									'Agriculture',
									'Carbon Crediting',
									'Technology',
									'Education',
								]}
								className='highlight-text d-inline-block text-primary'
								speed='150'
								eraseSpeed='100'
								typingDelay='400'
								eraseDelay='800'
								cursorClassName='typed-cursor'
							/>
						</p>
					</b>
				</p>
			</header>
		</div>
		<ScrollLink
			to='minifibre'
			className='goto-next'
			activeClass='active'
			smooth={true}
			offset={0}
			duration={1500}
			spy={true}
		>
			Next
		</ScrollLink>
	</section>
);

export default Banner;
