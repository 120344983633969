import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { Fade } from 'react-reveal';

const MiniVerti = props => (
  <section
    id="miniverti"
    className="spotlight style3 left inactive"
    style={{ backgroundImage: `url(https://images.unsplash.com/photo-1623227866842-a7d984204a50?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2796&q=80` }}
  >
    <Fade left big>
      <div className="content">
        <header>
          <h2>VertiStacks</h2>
          <p>Redefining farming technologies.</p>
        </header>
        <p><b>
        SGP Foods’ VertiStacks is a solution that enables vertical farming of food crops via IoT technology. VertiStacks comprises of a system that is equipped with physical cultivation apparatuses embedded with digital toolsets that assist with the cultivation process. 
        </b></p>
        <ul className="actions">
          <li>
            <a href="/tech/vertistacks" className="button">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </Fade>
    <ScrollLink
      to="partners"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={0}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default MiniVerti
