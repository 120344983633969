import React from 'react';
import { Fade } from 'react-reveal';
import { Link as ScrollLink } from 'react-scroll';
import sme500 from '../assets/images/sme500.jpg';
import itelogo from '../assets/images/ITE_Logo.png';
import sutdlogo from '../assets/images/sutd_logo.png';
import astarlogo from '../assets/images/astarlogo.png';
import prestigelogo from '../assets/images/prestigelogo.png';
import aspwomanlogo from '../assets/images/aspwomanlogo.png';
import Singapore_Trusted_Enterprise_Logo from '../assets/images/Singapore_Trusted_Enterprise_Logo.png'
import Food_Award_Plaque from '../assets/images/Food_Award_Plaque.jpeg'

const Partners = (props) => (
	<section>
		<Fade up>
			<section id='partners' className='wrapper style1 special fade-down'>
				<div className='container'>
					<header className='major'>
						<h2>Our Partners &amp; Accolades</h2>
						<p>Our achievements thus far.</p>
					</header>
					<section className='col-12'>
						<div
							className='box alt'
							style={{ backgroundColor: `white`, padding: `15px` }}
						>
							<section className='col-6 col-12-medium col-12-xsmall'>
								<img
									alt=''
									src={sme500}
									style={{
										maxWidth: `100%`,
									}}
								/>
							</section>
							<br />
							<div className='row gtr-150'>
								<section
									className='col-4 col-12-medium col-12-xsmall'
									style={{ marginLeft: `15vw` }}
								>
									<img
										alt=''
										src={prestigelogo}
										style={{
											maxWidth: `110%`,
											paddingRight: `80px`,
										}}
									/>
								</section>
								<section className='col-4 col-12-medium col-12-xsmall'>
									<img
										alt=''
										src={aspwomanlogo}
										style={{
											paddingTop: `19px`,
											maxWidth: `100%`,
										}}
									/>
								</section>
							</div>
							<br />
							<br />
							<div className='row gtr-150'>
								<section
									className='col-4 col-12-medium col-12-xsmall'
									style={{ marginLeft: `15vw` }}
								>
									<img
										alt=''
										src={Singapore_Trusted_Enterprise_Logo}
										style={{
											maxWidth: `110%`,
											paddingRight: `80px`,
										}}
									/>
								</section>
								<section className='col-4 col-12-medium col-12-xsmall'>
									<img
										alt=''
										src={Food_Award_Plaque}
										style={{
											paddingTop: `19px`,
											maxWidth: `100%`,
										}}
									/>
								</section>
							</div>
							<br />
							<br />
							<div className='row gtr-150'>
								<div className='col-4 col-12-medium'>
									<img
										alt=''
										src={itelogo}
										style={{
											maxHeight: `250px`,
											maxWidth: `100%`,
										}}
									></img>

									<h3 style={{ textAlign: `center`, color: `black` }}>ITE</h3>
									<p style={{ textAlign: `center`, color: `black` }}>
										5-Year Collaboration in High-Tech Urban Farming
									</p>
								</div>
								<div className='col-4 col-12-medium'>
									<img
										alt=''
										src={sutdlogo}
										style={{
											maxHeight: `250px`,
											maxWidth: `96%`,
										}}
									></img>

									<h3 style={{ textAlign: `center`, color: `black` }}>SUTD</h3>
									<p style={{ textAlign: `center`, color: `black` }}>
										Capstone with Final Year Students
									</p>
								</div>
								<div className='col-4 col-12-medium'>
									<img
										alt=''
										src={astarlogo}
										style={{
											maxHeight: `250px`,
											maxWidth: `100%`,
										}}
									></img>

									<h3 style={{ textAlign: `center`, color: `black` }}>
										A*STAR
									</h3>
									<p style={{ textAlign: `center`, color: `black` }}>
										In partnership with SIMTech
									</p>
								</div>
							</div>
						</div>
					</section>
				</div>
			</section>
		</Fade>
		<ScrollLink
			to='five'
			className='goto-next'
			activeClass='active'
			smooth={true}
			offset={0}
			duration={1500}
			spy={true}
		>
			Next
		</ScrollLink>
	</section>
);

export default Partners;
