import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Partners from '../components/Partners';
import MiniCarbon from '../components/MiniCarbon';
import MiniVerti from '../components/MiniVerti';
import MiniFibre from '../components/MiniFiber';
import Features from '../components/Features';
// import VideoModal from '../components/VideoModal';

class Home extends React.Component {
	render() {
		return (
			<Layout location='/'>
				<Helmet
					htmlAttributes={{ lang: 'en' }}
					title='SGP Foods Pte. Ltd.'
					meta={[
						{ name: 'description', content: 'SGP Foods' },
						{
							name: 'keywords',
							content:
								'carbon crediting, carbon market, singapore, education, agriculture',
						},
					]}
				></Helmet>
				<Banner />
				<MiniFibre />
				<MiniCarbon />
				<MiniVerti />
				<Partners />
				<Features />
			</Layout>
		);
	}
}

export default Home;
