import React from 'react';

const Card = (props) => (
	<section className='col-4 col-12-medium' style={{ display: props.display }}>
		<div className='card'>
			<div className='card_image'>
				<img alt='' src={props.image} />
			</div>
			<div className='card_content'>
				<div className='card_date'>
					<span>{props.date}</span>
				</div>
				<div className='card_title'>{props.title}</div>

				<div className='card_description'>
					<span>{props.description}</span>
				</div>
			</div>
			<div className='card_more'>
				<a href={props.link}>
					<i className='fa fa-angle-right' /> <span>Read more</span>
				</a>
			</div>
		</div>
	</section>
);
export default Card;
