import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { Fade } from 'react-reveal';

const MiniFibre = props => (
  <section
    id="minifibre"
    className="spotlight style3 left inactive"
    style={{ backgroundImage: `url(https://post.healthline.com/wp-content/uploads/2018/10/Kale_Bowl_Salad-1200x628-Facebook.jpg` }}
  > 
    <Fade left big>
      <div className="content">
        <header>
          <h2>Allied Fibres Program</h2>
          <p>The Crop for the Future </p>
        </header>
        <p><b>
        Allied Fibres Program is a multi-year project where the main objective would be for SGP Foods to become a major supplier of allied fibre products worldwide. The allied fibre crop contains a multitude of uses ranging from being a food crop, cosmetics ingredient and building materials. In addition to having a large variety of uses, the allied fibre crop is extremely sustainable and environmentally friendly, with efficient land usage and low waste production.</b></p>
        <ul className="actions">
          <li>
            <a href="/tech/fibre" className="button">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </Fade>
    <ScrollLink
      to="minicarbon"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={0}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default MiniFibre
