
import React, { useState, useEffect } from 'react';
import { Fade } from 'react-reveal';
import Card from './Card';
import zaobao_image from '../assets/images/zaobao_image.jpg';
import pmo_image from '../assets/images/pmo_image.png';
import sutd_image from '../assets/images/sutd_image.jpg';
import '../assets/css/news.css';

function Features() {
	const [slideIndex, setSlideIndex] = useState(0);
	const [slides, setSlides] = useState(null);
	const [width, setWidth] = useState(null);

	useEffect(() => {
		setSlides(document.getElementById('card_container').children);
		setWidth(window.innerWidth);
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, width);

	function plusSlidesCom(n) {
		console.log(n);
		if ((slideIndex + n) * 3 >= slides.length) {
			setSlideIndex(0);
			showSlidesCom(0);
		} else if (slideIndex + n < 0) {
			setSlideIndex(slides.length / 3 - 1);
			showSlidesCom(slides.length / 3 - 1);
		} else {
			let temp = slideIndex + n;
			setSlideIndex(temp);
			showSlidesCom(temp);
		}
	}

	function showSlidesCom(n) {
		var first = (n + 1) * 3 - 3;
		var second = (n + 1) * 3 - 2;
		var third = (n + 1) * 3 - 1;
		for (var i = 0; i < slides.length; i++) {
			if (i == first || i == second || i == third) {
				slides[i].style.display = 'block';
			} else {
				slides[i].style.display = 'none';
			}
		}
	}

	function plusSlidesPhone(n) {
		console.log('phone');
		if (slideIndex + n > slides.length - 1) {
			// if meet last item
			setSlideIndex(0);
			showSlidesPhone(0);
		} else if (slideIndex + n < 0) {
			// if meet first item
			setSlideIndex(slides.length - 1);
			showSlidesPhone(slides.length - 1);
		} else {
			let temp = slideIndex + n;
			setSlideIndex(temp);
			showSlidesPhone(temp);
		}
	}
	function showSlidesPhone(n) {
		for (var i = 0; i < slides.length; i++) {
			if (i == n) {
				slides[i].style.display = 'block';
			} else {
				slides[i].style.display = 'none';
			}
		}
	}

	return (
		<section>
			<Fade up>
				<section className='fade-down'>
					<div className='container'>
						<header className='major'>
							<h2>Our Features</h2>
						</header>
						<div className='chevron_group'>
							<i
								class='fas fa-chevron-left'
								onClick={() =>
									width >= 992 ? plusSlidesCom(-1) : plusSlidesPhone(-1)
								}
							></i>
							<i
								class='fas fa-chevron-right'
								onClick={() =>
									width >= 992 ? plusSlidesCom(1) : plusSlidesPhone(1)
								}
							></i>
						</div>
						<div id='card_container' className='news_content row'>
							<Card
								title='DPM Heng Swee Keat at the SUTD Ministerial Forum 2021'
								date="16 April 2021 • Prime Minister's Office"
								description='Speech by Deputy Prime Minister, Coordinating Minister for Economic Policies and Minister for Finance Heng Swee Keat at the Singapore University of Technology and Design (SUTD) Ministerial Forum on 16 April 2021. All our students in the auditorium and online A very good afternoon to all of you...'
								image={pmo_image}
								link='https://www.pmo.gov.sg/Newsroom/DPM-Heng-Swee-Keat-at-the-SUTD-Ministerial-Forum-2021'
								display='block'
							/>
							<Card
								title='大胆创业筑梦'
								date='25 March 2021 • 早报'
								description='白芸宁在2019年毕业于新加坡科技大学的科技创业直通课程，主修工程系统与设计，考获学士课程后直通硕士学位。2015年曾协助教育起步公司策划课程与授课
								2015年曾协助教育起步公司策划课程与授课，2017年尝试研发手提打鼓仪器及开创音乐起步公司，25岁的白芸宁（右）年纪轻轻就累积了不同的创业经验，如今也经营...'
								image={zaobao_image}
								link='https://www.zaobao.com.sg/zlifestyle/powerup/story20210325-1133884'
								display={width >= 992 ? 'block' : 'none'}
							/>
							<Card
								title='Meet our incredible SUTD #womenintech'
								date='4 March 2021 • SUTD'
								description='At SUTD, we pride ourselves on encouraging gender diversity as part of our mission to nurture technically-grounded leaders and innovators. Our holistic approach has attracted a cohort of more than 40% females, compared to the rest of Singapore where females are enrolled in less than 20% of spots in...'
								image={sutd_image}
								link='https://wearesutd.sutd.edu.sg/women-in-sutd/meet-our-incredible-sutd-womenintech/'
								display={width >= 992 ? 'block' : 'none'}
							/>
						</div>
					</div>
				</section>
			</Fade>
		</section>
	);
}
export default Features;
